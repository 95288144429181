// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
// Default styles for toasts
@import '~react-toastify/dist/ReactToastify.css';

.securitize-app {

  .app-body {
    margin-left: 112px;
    transition: all 0.25s;
    padding: 44px 42px;

    @media (max-width: 991.98px) {
      margin-left: 0;
      margin-top: 0;
      padding: 20px 10px 90px;
    }

    .main .container-fluid {
      @include media-breakpoint-down(sm) {
        padding: 0 15px;
      }
    }
  }

  &__header {
    max-height: 150px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__issuer-logo {
      max-height: 70px;
      max-width: 140px;
      @include media-breakpoint-down(sm) {
        max-height: 40px;
        max-width: 80px;
      }
    }

  }
}
