// Variable overrides

$white: #fff !default;
$black: #000 !default;
$dark-blue: #0283a8 !default;
$rose-red: #f4374e !default;

$gray: #dadada !default;
$gray-5: #f4f3f3;
$gray-10: #f9f9f9;
$gray-15: #ececec;
$gray-20: #d3d2d2;
$gray-30: #b3b2b2;
$gray-40: #90979e;
$gray-50: #848b93;
$gray-75: #48525e;
$gray-80: #222f3d;

$blue-10: #e6f3f6;
$blue-20: #e8f6fa;
$blue-25: #c0eff0;
$blue-50: #80c1d3;
$blue-70: #15a3cf;
$blue-80: #0a1828;

$blue-gray-10: #42a2be;
$blue-gray-20: #006a88;

$error: #fc233e;
$red-25: #ffcbd3;
$red-30: #fc0017;

$green-10: #04bec4;

$yellow-25: #fce7c6;
$yellow-40: #f99700;

// shadows
$shadow-light: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
$shadow-inset: inset 0px -6px 6px -6px rgba(0, 0, 0, 0.1);

// fonts
$Roboto: 'Roboto', sans-serif;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
