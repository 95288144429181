@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic');

@font-face {
  font-family: 'securitize-icons';
  src: url('../assets/fonts/fontello/securitize-icons.eot?12788403');
  src: url('../assets/fonts/fontello/securitize-icons.eot?12788403#iefix') format('embedded-opentype'),
  url('../assets/fonts/fontello/securitize-icons.woff2?12788403') format('woff2'),
  url('../assets/fonts/fontello/securitize-icons.woff?12788403') format('woff'),
  url('../assets/fonts/fontello/securitize-icons.ttf?12788403') format('truetype'),
  url('../assets/fonts/fontello/securitize-icons.svg?12788403#securitize-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "securitize-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "securitize-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-twitter:before { content: '\e800'; } /* '' */
.icon-fb:before { content: '\e801'; } /* '' */
.icon-whatsapp:before { content: '\e802'; } /* '' */
.icon-telegram:before { content: '\e803'; } /* '' */
.icon-medium:before { content: '\e804'; } /* '' */
.icon-bitcoin:before { content: '\e805'; } /* '' */
.icon-ethereum:before { content: '\e806'; } /* '' */
.icon-timeless:before { content: '\e807'; } /* '' */
.icon-point-up:before { content: '\e846'; } /* '' */
.icon-point-down:before { content: '\e847'; } /* '' */
.icon-account:before { content: '\e848'; } /* '' */
.icon-add:before { content: '\e849'; } /* '' */
.icon-check:before { content: '\e84a'; } /* '' */
.icon-icon-eye-hide:before { content: '\e84b'; } /* '' */
.icon-icon-eye-show:before { content: '\e84c'; } /* '' */
.icon-passport:before { content: '\e84d'; } /* '' */
.icon-window:before { content: '\e84e'; } /* '' */
.icon-news:before { content: '\e84f'; } /* '' */
.icon-address-verified:before { content: '\e850'; } /* '' */
.icon-address:before { content: '\e851'; } /* '' */
.icon-resize:before { content: '\e852'; } /* '' */
.icon-arrow-left:before { content: '\e853'; } /* '' */
.icon-arrow-right:before { content: '\e854'; } /* '' */
.icon-arrow_right:before { content: '\e855'; } /* '' */
.icon-arrow_left:before { content: '\e856'; } /* '' */
.icon-articles:before { content: '\e857'; } /* '' */
.icon-flag:before { content: '\e858'; } /* '' */
.icon-bookmark:before { content: '\e859'; } /* '' */
.icon-calculator:before { content: '\e85a'; } /* '' */
.icon-calendar:before { content: '\e85b'; } /* '' */
.icon-camera:before { content: '\e85c'; } /* '' */
.icon-certificate:before { content: '\e85d'; } /* '' */
.icon-entity:before { content: '\e85e'; } /* '' */
.icon-clock:before { content: '\e85f'; } /* '' */
.icon-copy:before { content: '\e860'; } /* '' */
.icon-delete:before { content: '\e861'; } /* '' */
.icon-details:before { content: '\e862'; } /* '' */
.icon-document:before { content: '\e863'; } /* '' */
.icon-download:before { content: '\e864'; } /* '' */
.icon-driver-licence:before { content: '\e865'; } /* '' */
.icon-email:before { content: '\e866'; } /* '' */
.icon-error:before { content: '\e867'; } /* '' */
.icon-exit:before { content: '\e868'; } /* '' */
.icon-faq:before { content: '\e869'; } /* '' */
.icon-folder-lock:before { content: '\e86a'; } /* '' */
.icon-folder:before { content: '\e86b'; } /* '' */
.icon-home:before { content: '\e86c'; } /* '' */
.icon-id:before { content: '\e86d'; } /* '' */
.icon-image:before { content: '\e86e'; } /* '' */
.icon-individual_info:before { content: '\e86f'; } /* '' */
.icon-info:before { content: '\e870'; } /* '' */
.icon-key:before { content: '\e871'; } /* '' */
.icon-laptop:before { content: '\e872'; } /* '' */
.icon-people:before { content: '\e873'; } /* '' */
.icon-liquidity:before { content: '\e874'; } /* '' */
.icon-wallet:before { content: '\e875'; } /* '' */
.icon-log-out:before { content: '\e876'; } /* '' */
.icon-market:before { content: '\e877'; } /* '' */
.icon-notes:before { content: '\e878'; } /* '' */
.icon-notification:before { content: '\e879'; } /* '' */
.icon-password:before { content: '\e87a'; } /* '' */
.icon-pause:before { content: '\e87b'; } /* '' */
.icon-pin:before { content: '\e87c'; } /* '' */
.icon-play:before { content: '\e87d'; } /* '' */
.icon-portfolio:before { content: '\e87e'; } /* '' */
.icon-receipt:before { content: '\e87f'; } /* '' */
.icon-refresh:before { content: '\e880'; } /* '' */
.icon-residence-card:before { content: '\e881'; } /* '' */
.icon-send:before { content: '\e882'; } /* '' */
.icon-settings:before { content: '\e883'; } /* '' */
.icon-smartphone:before { content: '\e884'; } /* '' */
.icon-success:before { content: '\e885'; } /* '' */
.icon-tablet:before { content: '\e886'; } /* '' */
.icon-tax:before { content: '\e887'; } /* '' */
.icon-video:before { content: '\e888'; } /* '' */
.icon-upload:before { content: '\e889'; } /* '' */
.icon-thumb-up:before { content: '\e88b'; } /* '' */
.icon-syncing:before { content: '\e88c'; } /* '' */
.icon-plus:before { content: '\e88d'; } /* '' */
.icon-pie-chart:before { content: '\e88e'; } /* '' */
.icon-pencil:before { content: '\e88f'; } /* '' */
.icon-paper-clip:before { content: '\e890'; } /* '' */
.icon-list:before { content: '\e891'; } /* '' */
.icon-info-small:before { content: '\e892'; } /* '' */
.icon-image-1:before { content: '\e893'; } /* '' */
.icon-flower:before { content: '\e894'; } /* '' */
.icon-dividends:before { content: '\e895'; } /* '' */
.icon-column-chart:before { content: '\e896'; } /* '' */
.icon-coins:before { content: '\e897'; } /* '' */
.icon-profile:before { content: '\e898'; } /* '' */
